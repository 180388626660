@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-loading-skeleton/dist/skeleton.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

#msg-container {
  text-align: center;
}

button.thumbnail {
  padding: 0;
}

.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

th,
td {
  vertical-align: middle !important;
  white-space: nowrap;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

/* ----------- Map Styling ------------- */
.full-box {
  height: 50vh;
  max-width: initial;
}

.p-bold {
  margin: 1rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}

/* --------------------- */

.rating {
  padding: 0.2rem 0.7rem;
  width: 3rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  display: inline-block;
  border-radius: 5px;
}

.on,
.green {
  font-size: 2em;
  color: rgba(0, 128, 0, 0.836);
  transform: all 0.5s;
}

.off,
.red {
  font-size: 2em;
  color: rgba(226, 86, 43, 0.747);
  transform: all 0.5s;
}

.red {
  font-size: 1.2em;
}

.header {
  border-bottom: 1px solid #dee2e6;
  z-index: 1020;
  background: rgb(15, 145, 122);
  background: linear-gradient(
    120deg,
    rgba(15, 145, 122, 1) 0%,
    rgba(131, 227, 124, 1) 75%
  );
}

.header-link {
  padding: 0.5rem 1.75rem !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.header-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff !important;
}

.td-img {
  transition: all 0.5s !important;
}

.td-img:hover {
  transform: scale(3);
  transition: all 0.5s !important;
}

.open-model {
  font-size: 2em;
  color: blue;
  cursor: pointer;
}

.card-header-primary {
  background-color: #02a97b5e;
}

.accordion-header {
  padding: 0;
  background-color: transparent;
  display: flex;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  /* box-shadow: var(--bs-accordion-btn-focus-box-shadow); */
  box-shadow: none;
}
.card-title {
  display: inline-block;
}

.card-tools {
  float: right;
  cursor: pointer;
}

.navbar-nav a.right-profile-logo {
  color: #001c4e !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-profile-logo > img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
}

/* ------------------- */
.main-wrapper {
  display: flex;
  min-height: 100%;
}

.body-wrapper {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out !important;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
  flex: 1;
  transition: width 0.3s ease-in-out;
}

.body-wrapper.mini-body {
  margin-left: 250px;
}

.body-wrapper.full-body {
  margin-left: 4.6rem;
}

.sidebar-wrapper {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  background: linear-gradient(
    rgba(15, 145, 122, 1) 0%,
    rgba(131, 227, 124, 1) 75%
  );
  z-index: 1038;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease-in-out;
}

.wrapper {
  padding: 0 1rem;
}

footer {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
}

table.table {
  margin-bottom: 0;
}

.table-title {
  font-size: 1.2rem;
  font-weight: 500;
}

.search-box {
  width: 100%;
}

.dropdown-toggle:hover,
.dropdown-toggle,
.dropdown:hover {
  background-color: transparent;
  border: none;
}

.dropdown button {
  background: transparent !important;
}

.dropdown-menu span:active,
.dropdown-menu Link:active {
  background-color: transparent !important;
  color: black;
}

.login-page {
  background-color: #e9ecef;
}

.login-box {
  width: 90%;
}

.login-logo,
.register-logo {
  font-size: 2.1rem;
  font-weight: 300;
  margin-bottom: 0.9rem;
  text-align: center;
}

.login-logo a {
  color: #495057;
}

.bookings-header-root {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .search-box {
    margin-top: 0.5rem;
  }

  .pagination {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-border-radius: 0.25rem;
  }

  .bookings-header-root {
    align-items: flex-start;
    flex-direction: column;
  }

  .filter-title {
    font-size: 0.8rem;
  }
}

@media (min-width: 576px) {
  .login-box {
    width: 360px;
  }

  .search-box {
    width: 50%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .search-box {
    width: 30%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

.bg-info,
.bg-info > a {
  color: #fff !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.small-box {
  border-radius: 0.25rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.125), 0 1px 3px rgba(0, 0, 0, 0.2);
  display: block;
  margin-bottom: 20px;
  position: relative;
}

.small-box > .inner {
  padding: 10px;
  color: #fff;
}

.small-box h3,
.small-box p {
  z-index: 5;
}

.small-box h3 {
  font-size: 2.2rem;
  font-weight: 700;
  margin: 0 0 10px;
  padding: 0;
  white-space: nowrap;
}

.small-box p {
  font-size: 1rem;
}

.small-box .icon {
  color: rgba(0, 0, 0, 0.15);
  z-index: 0;
}

.small-box .icon svg {
  font-size: 70px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: -webkit-transform 0.3s linear;
  transition: transform 0.3s linear;
  transition: transform 0.3s linear, -webkit-transform 0.3s linear;
}

.small-box:hover .icon > svg {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.small-box > .small-box-footer {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
  display: block;
  padding: 3px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 10;
}

.col1 {
  max-width: 55vw;
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: auto !important;
}
.col1 > .car__item {
  width: 12rem;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: 1px solid lightslategray;
}
.col1 > .car__item > img {
  width: 12rem !important;
  object-fit: contain !important;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.col1 > .car__item > .car__item-content {
  padding: 0.5rem;
}
.col1 > .car__item > .car__item-content > .car__item-info {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
}

.col1 > .car__item > .car__item-content > .car__item-info > .car-item-btm {
  display: flex;
  gap: 5px;
}
.col1
  > .car__item
  > .car__item-content
  > .car__item-info
  > .car-item-btm
  > .span_m {
  transform: translateY(2px);
}
.col1 > .car__item > .car__item-content > button {
  margin-top: 10px;
  width: 100%;
}
